import React, { useMemo } from 'react';
import { MyMeetingsTable } from './components/myMeetingsTable/MyMeetingsTable';
import { AddBotModal } from '../MyRelationships/components/addBotModal/AddBotModal';
import { InviteAssistantButton } from '../../components/SoundWave';
import { useTrackPageView } from '../../hooks';
import { UpcomingMeetings } from './components/upcomingMeetings/UpcomingMeetings';
import { MeetingRecordsFilters } from './components/MeetingRecordsFilters/MeetingRecordsFilters';
import { Features } from '../../types';
import { useAppSelector } from '../../store/hooks';

export const MyMeetings: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  const isCalendarEnabled = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.CALENDAR_AGENDA
      )?.enabled,
    [user]
  );

  const attrs = {
    container: {
      className: 'my-meetings',
    },

    header: {
      wrapper: {
        className: 'my-meetings__header',
      },

      title: {
        className: 'my-meetings__header__title',
      },

      controls: {
        className: 'my-meetings__header__controls',
      },
    },

    body: {
      wrapper: {
        className: 'my-meetings__body',
      },
    },
  };

  useTrackPageView();

  return (
    <div {...attrs.container}>
      <div {...attrs.header.wrapper}>
        <div {...attrs.header.title}>My Meetings</div>
        <div {...attrs.header.controls}>
          <MeetingRecordsFilters />
          <InviteAssistantButton />
        </div>
      </div>
      <div {...attrs.body.wrapper}>
        <MyMeetingsTable />
        {isCalendarEnabled ? <UpcomingMeetings /> : null}
        <AddBotModal />
      </div>
    </div>
  );
};
