import React from 'react';
import { MeetingStatus } from '../../../../../types/meetingDetails.types';

export const getOpenButtonTooltipProps = (
  meetingStatus?: MeetingStatus,
  isAnalysisDone?: boolean,
  onIntercomClick?: () => void
): { text?: React.ReactNode; width?: number } => {
  switch (meetingStatus) {
    case MeetingStatus.IN_PROGRESS: {
      return {
        text: 'Meeting is in progress',
        width: 149,
      };
    }
    case MeetingStatus.BOT_FAILED_TO_JOIN: {
      return {
        text: 'Meeting not recorded: AI Assistant was not allowed.',
        width: 256,
      };
    }
    case MeetingStatus.CALL_POST_PROCESSING_FAILED: {
      const props = {
        intercomLink: {
          type: 'button' as const,
          onClick: onIntercomClick,
        },
        emailLink: {
          href: 'mailto:contact@substrata.me',
          rel: 'noopener noreferrer',
        },
      };

      return {
        text: (
          <>
            We had a problem processing your meeting data. Please contact us via{' '}
            <button {...props.intercomLink}>Intercom</button> or at{' '}
            <a {...props.emailLink}>contact@substrata.me</a> to resolve the
            issue.
          </>
        ),
        width: 256,
      };
    }
    default: {
      return isAnalysisDone
        ? {}
        : {
            text:
              'Meeting data is being processed and will be available shortly.',
            width: 256,
          };
    }
  }
};
