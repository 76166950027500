import { UserConnection } from '../../../../../types';

export const getModalText = ({
  connectionId,
  isEmailAnalyzerEnabled,
  isSendEmailsEnabled,
  isCalendarEnabled,
}: {
  connectionId: Exclude<UserConnection, UserConnection.Zoom>;
  isEmailAnalyzerEnabled: boolean;
  isSendEmailsEnabled: boolean;
  isCalendarEnabled: boolean;
}): string => {
  const features = {
    [UserConnection.GmailRead]: 'Email Analysis',
    [UserConnection.GmailWrite]: 'Send Emails',
    [UserConnection.Calendar]: 'Calendar',
  };

  const disabledFeatures = [
    ...(connectionId !== UserConnection.GmailRead && isEmailAnalyzerEnabled
      ? ['Email Analysis']
      : []),
    ...(connectionId !== UserConnection.GmailWrite && isSendEmailsEnabled
      ? ['Send Emails']
      : []),
    ...(connectionId !== UserConnection.Calendar && isCalendarEnabled
      ? ['Calendar']
      : []),
  ];

  const baseMessage = `Disconnecting ${features[connectionId]} will ${
    disabledFeatures.length > 0
      ? `disable the ${disabledFeatures.join(' and ')} feature${
          disabledFeatures.length > 1 ? 's' : ''
        } and `
      : ''
  }log you out. Would you like to proceed?`;

  return baseMessage;
};
