import React, { useEffect, useRef, useState } from 'react';
import { Skeleton } from 'antd';
import { DateTime } from 'luxon';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Scrollbar } from '../../../../components';
import { ReassignAccountIcon } from './assets/ReassignAccountIcon';
import { UnassignAccountIcon } from './assets/UnassignAccountIcon';
import { getEventSourceIcon } from './utils/getEventSourceIcon';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getRelationshipEvents } from '../../../../store/relationshipDetails/relationshipDetails.thunks';
import { RelationshipEventType } from '../../../../types/relationshipEvent.types';
import {
  openAssignAccountModal,
  openUnassignAccountModal,
} from '../../../../store/relationshipFlows/relationshipFlows.slice';
import { threeDotMenuIcon } from './assets';
import { Contact } from '../../../../types/contact.types';
import {
  Avatar,
  AvatarSize,
  Button,
  ButtonSize,
  ButtonVariant,
  Dropdown,
  Tooltip,
  TooltipPosition,
} from '../../../../components/SoundWave';
import { MeetingStatus } from '../../../../types/meetingDetails.types';
import { getOpenButtonTooltipProps } from '../../../MeetingRecords/components/MeetingRecordsList/utils/getOpenButtonTooltipProps';

interface ThreeDotMenu {
  open: boolean;
  id: string;
}

export const RelationshipEvents: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const account = useAppSelector((state) => state.relationshipDetails.account);
  const events = useAppSelector((state) => state.relationshipDetails.events);

  const isEventsLoading = useAppSelector(
    (state) => state.relationshipDetails.isLoading.events
  );

  const nextPage = useAppSelector(
    (state) => state.relationshipDetails.eventsPagination.nextPage
  );

  const history = useHistory();

  const [optionsExpanded, setOptionsExpanded] = useState<ThreeDotMenu>({
    open: false,
    id: '',
  });

  const [isLastItemVisible, setIsLastItemVisible] = useState(false);

  const { show } = useIntercom();

  const request = useRef<Promise<unknown> & { abort: () => void }>();

  const scrollableNodeRef = useRef<HTMLElement | null>(null);

  const rowVirtualizer = useVirtualizer({
    count: isEventsLoading ? events.length + 5 : events.length,
    getScrollElement: () => {
      return scrollableNodeRef.current;
    },
    estimateSize: () => 64,
    overscan: 0,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  const attrs = {
    container: {
      className: 'rs-events',
    },
    headerRow: {
      className: 'rs-events__row rs-events__row--header',
    },
    row: {
      className: 'rs-events__row',
    },
    cell: {
      className: 'rs-events__cell',
    },
    rowsWrapper: {
      style: {
        height: `${rowVirtualizer.getTotalSize()}px`,
        width: '100%',
        position: 'relative' as const,
      },
    },
    sourceIcon: {
      className: 'rs-events__source-icon',
    },
    title: {
      className: 'rs-events__title',
    },
    editIcon: {
      className: 'rs-events__edit-icon',
    },
    participants: {
      wrapper: {
        className: 'rs-events__participants',
      },

      avatar: {
        className: 'rs-events__participants-avatar',
      },

      left: {
        className: 'rs-events__participants-text',
      },
    },
    scrollable: {
      scrollableNodeProps: {
        ref: scrollableNodeRef,
      },
      className: 'rs-events__scrollable',
    },
    emptyContent: {
      className: 'rs-events__empty-content',
    },
    textSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 130,
      },
      title: {
        style: {
          width: 130,
          height: 24,
          margin: 0,
          borderRadius: 4,
        },
      },
    },
  };

  const renderRows = () =>
    virtualItems.map(({ index, size, start }) => {
      const isLoaderRow = index > events.length - 1;
      const event = events[index];
      const EventIcon = getEventSourceIcon(event?.type);
      const dateString = DateTime.fromISO(event?.startDateTime).toFormat(
        'MMM dd, yyyy, hh:mm a'
      );

      const toggleThreeDotMenu = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOptionsExpanded({
          open: !optionsExpanded.open,
          id: event?.touchPointId || '',
        });
      };

      const getOpenButtonText = (meetingStatus?: MeetingStatus): string => {
        switch (meetingStatus) {
          case MeetingStatus.IN_PROGRESS: {
            return 'In Progress';
          }
          case MeetingStatus.CALL_POST_PROCESSING_FAILED:
          case MeetingStatus.BOT_FAILED_TO_JOIN: {
            return 'N/A';
          }
          default: {
            return 'Open';
          }
        }
      };

      const isReady =
        event?.eventDetails?.meetingStatus !== MeetingStatus.IN_PROGRESS &&
        event?.eventDetails?.meetingStatus !==
          MeetingStatus.CALL_POST_PROCESSING_FAILED &&
        event?.eventDetails?.meetingStatus !== MeetingStatus.BOT_FAILED_TO_JOIN;

      const openButtonText = getOpenButtonText(
        event?.eventDetails?.meetingStatus
      );

      const popupIsOpen =
        event?.touchPointId === optionsExpanded.id && optionsExpanded.open;

      const openEventAction = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (!event || !isReady) {
          return;
        }

        if (event.type === RelationshipEventType.ONLINE_MEETING) {
          history.push(
            `/orgs/${user?.orgId}/users/${user?.userId}/relationships/${event.accountId}/meetings/${event.touchPointId}`
          );
        } else {
          history.push(
            `/orgs/${user?.orgId}/users/${user?.userId}/relationships/${event.accountId}/threads/${event.touchPointId}`
          );
        }
      };

      const getButtonVariant = () => {
        if (
          !isReady &&
          event?.eventDetails?.meetingStatus === MeetingStatus.IN_PROGRESS
        ) {
          return ButtonVariant.Success;
        }

        if (
          (!isReady &&
            event?.eventDetails?.meetingStatus ===
              MeetingStatus.CALL_POST_PROCESSING_FAILED) ||
          event?.eventDetails?.meetingStatus ===
            MeetingStatus.BOT_FAILED_TO_JOIN
        ) {
          return ButtonVariant.Error;
        }

        return ButtonVariant.Secondary;
      };

      const props = {
        container: {
          key: index,
          className: 'rs-events__row',
          onClick: openEventAction,
          style: {
            position: 'absolute' as const,
            top: 0,
            left: 0,
            width: '100%',
            height: `${size}px`,
            transform: `translateY(${start}px)`,
            zIndex: popupIsOpen ? 1 : 0,
          },
        },
        button: {
          size: ButtonSize.XS,
          disabled: !isReady,
          variant: getButtonVariant(),
          onClick: openEventAction,
        },
        tooltip: {
          simplified: true,
          position: TooltipPosition.TOP_END,
          ...getOpenButtonTooltipProps(
            event?.eventDetails?.meetingStatus,
            false,
            show
          ),
        },
        threeDotMenu: {
          wrapper: {
            className: `${
              optionsExpanded.open && event?.touchPointId === optionsExpanded.id
                ? 'open'
                : ''
            } rs-events__three-dot-menu`,
            onClick: toggleThreeDotMenu,
          },
          icon: {
            className: 'rs-events__three-dot-menu-icon',
            src: threeDotMenuIcon,
          },
        },
        dropdown: {
          isOpened: popupIsOpen,
          contentClassName: 'rs-events__dropdown',
          closeOnScroll: true,
          onClose: () => {
            setOptionsExpanded({
              open: !optionsExpanded.open,
              id: '',
            });
          },
          options: [
            {
              label: 'Reassign to',
              icon: <ReassignAccountIcon />,
              className: 'rs-events__dropdown-option',
              onClick: () => {
                setOptionsExpanded({
                  open: false,
                  id: '',
                });

                if (event) {
                  dispatch(
                    openAssignAccountModal({
                      eventId: event.touchPointId,
                      isReassignFlow: true,
                    })
                  );
                }
              },
            },
            {
              label: 'Unassign',
              icon: <UnassignAccountIcon />,
              className: 'rs-events__dropdown-option',
              onClick: () => {
                setOptionsExpanded({
                  open: false,
                  id: '',
                });

                if (event) {
                  dispatch(openUnassignAccountModal(event.touchPointId));
                }
              },
            },
          ],
        },
      };

      if (isLoaderRow) {
        return (
          <div {...props.container}>
            <div {...attrs.cell}>
              <Skeleton {...attrs.textSkeleton} />
            </div>
            <div {...attrs.cell}>
              <Skeleton {...attrs.textSkeleton} />
            </div>
            <div {...attrs.cell}>
              <Skeleton {...attrs.textSkeleton} />
            </div>
          </div>
        );
      }

      const createParticipantsCell = (contacts: Contact[] | []) => {
        if (contacts.length > 3) {
          return (
            <>
              {contacts.slice(0, 3).map((contact, ix) => (
                <React.Fragment key={ix}>
                  <Avatar
                    {...attrs.participants.avatar}
                    size={AvatarSize.XS}
                    src={contact?.metadata?.avatarSrc || ''}
                    name={contact?.metadata?.firstName || ''}
                  />
                </React.Fragment>
              ))}
              <div {...attrs.participants.avatar}>+{contacts.length - 3}</div>
            </>
          );
        }

        if (contacts.length >= 1 && contacts.length <= 3) {
          return contacts.map((contact, i) => (
            <React.Fragment key={i}>
              <Avatar
                {...attrs.participants.avatar}
                src={contact?.metadata?.avatarSrc || ''}
                name={contact?.metadata?.firstName || ''}
                size={AvatarSize.XS}
              />
            </React.Fragment>
          ));
        }

        return '-';
      };

      return (
        <div {...props.container}>
          <div {...attrs.cell}>
            <span {...attrs.title} onClick={openEventAction}>
              <EventIcon {...attrs.sourceIcon} />
              <span>{event?.eventDetails?.title || 'N/A'}</span>
            </span>
          </div>
          <div {...attrs.cell}>{dateString}</div>
          <div {...attrs.cell}>
            <div {...attrs.participants.wrapper}>
              {createParticipantsCell(event?.contacts || [])}
            </div>
          </div>
          <div {...attrs.cell}>
            {event?.eventDetails?.meetingStatus &&
            event?.eventDetails?.meetingStatus !== MeetingStatus.COMPLETED ? (
              <Tooltip {...props.tooltip}>
                <Button {...props.button}>{openButtonText}</Button>
              </Tooltip>
            ) : (
              <Button {...props.button}>{openButtonText}</Button>
            )}
          </div>
          <div {...attrs.cell}>
            <Dropdown {...props.dropdown}>
              <div {...props.threeDotMenu.wrapper}>
                <img {...props.threeDotMenu.icon} alt="" />
              </div>
            </Dropdown>
          </div>
        </div>
      );
    });

  useEffect(() => {
    const [lastItem] = [...virtualItems].reverse();
    setIsLastItemVisible(
      !!lastItem &&
        lastItem.index >= events.length - 1 &&
        !!nextPage &&
        !isEventsLoading
    );
  }, [events, isEventsLoading, nextPage, virtualItems]);

  useEffect(() => {
    if (isLastItemVisible && account) {
      request.current = dispatch(getRelationshipEvents(account.accountId));
    }
  }, [account, dispatch, isLastItemVisible]);

  useEffect(() => {
    return () => {
      request.current?.abort();
    };
  }, []);

  return (
    <div {...attrs.container}>
      <div {...attrs.headerRow}>
        <div {...attrs.cell}>Event</div>
        <div {...attrs.cell}>Date and Time</div>
        <div {...attrs.cell}>Participants</div>
        <div {...attrs.cell} />
        <div {...attrs.cell} />
      </div>
      <Scrollbar {...attrs.scrollable}>
        {events.length || isEventsLoading ? (
          <div {...attrs.rowsWrapper}>{renderRows()}</div>
        ) : (
          <div {...attrs.emptyContent}>
            You don’t have any events for this relationship
          </div>
        )}
      </Scrollbar>
    </div>
  );
};
