import React, { useMemo } from 'react';
import { ErrorIcon } from '../../../../components/Icons/ErrorIcon';
import { ConfirmationModal } from '../../../../components/SoundWave';
import { useFirebase } from '../../../../context';
import { closeRevokeGoogleScopesModal } from '../../../../store/auth/auth.slice';
import { revokeGoogleScopes } from '../../../../store/auth/auth.thunks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Features } from '../../../../types';
import { getModalText } from './utils';

export const RevokeGoogleScopesModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  const isOpened = useAppSelector(
    (state) => state.auth.revokeGoogleScopesModal.isOpened
  );

  const connectionId = useAppSelector(
    (state) => state.auth.revokeGoogleScopesModal.connectionId
  );

  const isRevokeGoogleScopesLoading = useAppSelector(
    (state) => state.auth.isLoading.revokeGoogleScopes
  );

  const { signOut } = useFirebase();

  const isEmailAnalyzerEnabled = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.EMAIL_ANALYZER
      )?.enabled,
    [user]
  );

  const isSendEmailsEnabled = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.REPLY_FROM_DASHBOARD
      )?.enabled,
    [user]
  );

  const isCalendarEnabled = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.CALENDAR_AGENDA
      )?.enabled,
    [user]
  );

  const attrs = {
    modal: {
      isOpened,
      icon: ErrorIcon,
      onClose: () => {
        dispatch(closeRevokeGoogleScopesModal());
      },
      description: getModalText({
        connectionId,
        isEmailAnalyzerEnabled,
        isSendEmailsEnabled,
        isCalendarEnabled,
      }),
      footerProps: {
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue',
        isConfirmButtonDanger: true,
        isLoading: isRevokeGoogleScopesLoading,
        onConfirm: () => {
          dispatch(revokeGoogleScopes()).then(() => {
            dispatch(closeRevokeGoogleScopesModal());
            signOut();
          });
        },
      },
    },
  };

  return <ConfirmationModal {...attrs.modal} />;
};
