import { RelationshipMemberSide } from './relationshipMember.types';
import { Contact } from './contact.types';
import { Account } from './account.types';
import { RelationshipEventNavigation } from './relationshipEvent.types';

export enum MeetingRecordSource {
  ZOOM = 'zoom',
  GOOGLE_MEET = 'meet',
  TEAMS = 'teams',
}

export enum MeetingStatus {
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
  CALL_POST_PROCESSING_FAILED = 'callPostProcessingFailed',
  BOT_FAILED_TO_JOIN = 'botFailedToJoin',
}

export interface MeetingRecord {
  callId: string;
  orgId: string;
  userOwnerId: string;
  touchPointId: string;
  platform: MeetingRecordSource;
  meetingName: string;
  joinUrl: string;
  startDateTime: string;
  account: Account | null;
  analysisDone: boolean;
  meetingStatus: MeetingStatus;
  duration: number | null;
}

export interface MeetingParticipantStats {
  talkTime: {
    total: number;
    percentage: number;
  } | null;
  screenShareTime: {
    total: number;
    percentage: number;
  } | null;
}

export interface MeetingParticipant {
  participantId: string;
  contactId: string | null;
  side: RelationshipMemberSide;
  alias: string | null;
  stats: MeetingParticipantStats | null;
}

export interface MeetingParticipantWithContactInfo extends MeetingParticipant {
  contact: Contact | null;
}

export interface MeetingAnalysis {
  summary: {
    summary: string;
    keyPoints: string[];
    topics: string[];
    actionItems: string[];
  };
}
export interface MeetingTranscript {
  text: string;
  startTimestamp: number;
  endTimestamp: number;
  participantId: string;
}

export interface MeetingTranscriptWithParticipantInfo
  extends MeetingTranscript {
  participant: MeetingParticipantWithContactInfo | null;
}

export interface MeetingDetails {
  account: Account | null;
  meetingId: string;
  title: string;
  duration: number | null;
  host: MeetingParticipant | null;
  participants: MeetingParticipant[];
  contacts: Contact[];
  analysis: MeetingAnalysis | null;
  recordingUrl: string | null;
  transcripts: MeetingTranscript[];
  createdAt: string;
  platform?: MeetingRecordSource;
  prevTouchPoint: RelationshipEventNavigation | null;
  nextTouchPoint: RelationshipEventNavigation | null;
  recordingThumbnail?: string | null;
}

export interface UpcomingMeeting {
  meetingId: string;
  start: string;
  summary: string;
  isBotScheduled: false;
  platform: string;
  participants: {
    email: string;
    metadata: {
      name: string;
      avatarSrc: string;
    };
  }[];
}
