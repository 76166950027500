import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { UpcomingMeetingsRow } from './components/upcomingMeetingsRow/UpcomingMeetingsRow';
import {
  MeetingRecordSource,
  UpcomingMeeting,
} from '../../../../types/meetingDetails.types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getUpcomingMeetings } from '../../../../store/meetingRecords/meetingRecords.thunks';
import { LoaderIcon } from '../../../../components/Icons/LoaderIcon';

export const UpcomingMeetings: React.FC = () => {
  const { data, status, nextPage } = useAppSelector(
    (state) => state.meetingRecords.upcoming
  );

  const { user, userConnections } = useAppSelector((state) => state.auth);

  const [localData, setLocalData] = useState<UpcomingMeeting[] | []>([]);

  const dispatch = useAppDispatch();

  const history = useHistory();

  useEffect(() => {
    dispatch(getUpcomingMeetings());
  }, []);

  useEffect(() => {
    if (data?.length && !localData.length) {
      setLocalData(data);
    }
  }, [data]);

  const today = DateTime.now().toFormat('d LLLL');
  const tomorrow = DateTime.now().plus({ days: 1 }).toFormat('d LLLL');
  const thirdDay = DateTime.now().plus({ days: 2 }).toFormat('d LLLL');
  const fourthDay = DateTime.now().plus({ days: 3 }).toFormat('d LLLL');
  const fifthDay = DateTime.now().plus({ days: 4 }).toFormat('d LLLL');
  const sixthDay = DateTime.now().plus({ days: 5 }).toFormat('d LLLL');
  const seventhDay = DateTime.now().plus({ days: 6 }).toFormat('d LLLL');

  const generateDataByDate = (date: DateTime<true> | string) => {
    return (
      data?.filter(
        (el) => (DateTime.fromISO(el?.start).toFormat('d LLLL') as any) === date
      ) || []
    );
  };

  const goToProfilePage = () => {
    if (user)
      history.push(
        `/orgs/${user.orgId}/users/${user.userId}/?selectedIntegrations=calendar`
      );
  };

  const attrs = {
    container: {
      className: 'upcoming-meetings',
    },

    header: {
      wrapper: {
        className: 'upcoming-meetings__header',
      },
      title: {
        className: 'upcoming-meetings__header-title',
      },
    },

    body: {
      wrapper: {
        className: 'upcoming-meetings__body',
      },

      loader: {
        wrapper: {
          className: 'upcoming-meetings__body__loader',
        },

        icon: {
          className: 'upcoming-meetings__body__loader-icon',
        },
      },

      dateTitle: {
        className: 'upcoming-meetings__body-date-title',
      },

      nodataText: {
        className: 'upcoming-meetings__body-no-data-text',
      },

      notConnected: {
        wrapper: {
          className: 'upcoming-meetings__body__not-connected',
        },

        title: {
          className: 'upcoming-meetings__body__not-connected-title',
        },

        text: {
          className: 'upcoming-meetings__body__not-connected-text',
        },

        link: {
          className: 'upcoming-meetings__body__not-connected-link',
          onClick: goToProfilePage,
        },
      },
    },
  };

  const createUISectionByDate = (date: DateTime<true> | string) => {
    let dateToShow: DateTime<true> | string = date;

    if (date.toString() === today) {
      dateToShow = 'Today';
    } else if (date.toString() === tomorrow) {
      dateToShow = 'Tomorrow';
    }

    return generateDataByDate(date)?.length ? (
      <>
        <div {...attrs.body.dateTitle}>{dateToShow}</div>
        {generateDataByDate(date).map((el, i) => (
          <UpcomingMeetingsRow
            {...el}
            meetingName={el.summary || 'N/A'}
            meetingTime={DateTime.fromISO(el.start)
              .setLocale('en-US')
              .toLocaleString(DateTime.TIME_SIMPLE)}
            meetingType={el.platform as MeetingRecordSource}
            meetingId={el.meetingId}
            status={el.isBotScheduled}
            key={`${el}|${i}`}
          />
        ))}
      </>
    ) : null;
  };

  const todaySection = createUISectionByDate(today);
  const tomorrewSection = createUISectionByDate(tomorrow);
  const thirdDaySection = createUISectionByDate(thirdDay);
  const fourthDaySection = createUISectionByDate(fourthDay);
  const fifthDaySection = createUISectionByDate(fifthDay);
  const sixthDaySection = createUISectionByDate(sixthDay);
  const seventhDaySection = createUISectionByDate(seventhDay);

  const calendarNotConnected = userConnections?.calendar !== true;

  const calendarNotConnectedSection = (
    <div {...attrs.body.notConnected.wrapper}>
      <div {...attrs.body.notConnected.title}>Calendar is not connected</div>
      <div {...attrs.body.notConnected.text}>
        Check your integration’s settings
      </div>
      <div {...attrs.body.notConnected.link}>Settings</div>
    </div>
  );

  const emptyState = !data?.length ? (
    <div {...attrs.body.nodataText}>
      Nothing scheduled for the next 7 days yet
    </div>
  ) : (
    <div {...attrs.body.nodataText}>Nothing Scheduled yet</div>
  );

  const upcomingMeets = localData?.length ? (
    <>
      {todaySection}
      {tomorrewSection}
      {thirdDaySection}
      {fourthDaySection}
      {fifthDaySection}
      {sixthDaySection}
      {seventhDaySection}
    </>
  ) : (
    emptyState
  );

  const loader = (
    <div {...attrs.body.loader.wrapper}>
      <LoaderIcon {...attrs.body.loader.icon} />
    </div>
  );

  const renderUpcomingMeetings = () => {
    if (calendarNotConnected) {
      return calendarNotConnectedSection;
    }
    if (status?.isLoading) {
      return loader;
    }
    if (!data?.length) {
      return emptyState;
    }

    return upcomingMeets;
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.header.wrapper}>
        <span {...attrs.header.title}>Upcoming Meetings</span>
      </div>
      <div {...attrs.body.wrapper}>{renderUpcomingMeetings()}</div>
    </div>
  );
};
