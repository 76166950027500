export const convertSecondsToMinutes = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);

  if (minutes < 1) {
    return '>1 min';
  }
  if (minutes === 1) {
    return '1 min';
  }

  return `${minutes} mins`;
};
