import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonVariant } from '../../../components/SoundWave';
import { useFirebase } from '../../../context';
import { openRevokeGoogleScopesModal } from '../../../store/auth/auth.slice';
import {
  disconnectZoom,
  getZoomOAuthUrl,
  sendZoomAuthCode,
} from '../../../store/auth/auth.thunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Features, UserConnection } from '../../../types';
import { IntegrationCardProps } from '../components/IntegrationCard/IntegrationCard';
import {
  CalendarIcon,
  GmailIcon,
  GoogleIcon,
  GoogleSmallIcon,
  ZoomIcon,
} from '../icons';

export const useIntegrations = (): IntegrationCardProps[] => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const userConnections = useAppSelector((state) => state.auth.userConnections);
  const zoomOAuthUrl = useAppSelector((state) => state.auth.zoomOAuthUrl);

  const isUserConnectionsLoading = useAppSelector(
    (state) => state.auth.isLoading.userConnections
  );

  const isConnectAnalyzeEmailsLoading = useAppSelector(
    (state) => state.auth.isLoading.connectAnalyzeEmails
  );

  const isConnectSendEmailsLoading = useAppSelector(
    (state) => state.auth.isLoading.connectSendEmails
  );

  const isConnectCalendarLoading = useAppSelector(
    (state) => state.auth.isLoading.connectCalendar
  );

  const isZoomOAuthUrlLoading = useAppSelector(
    (state) => state.auth.isLoading.zoomOAuthUrl
  );

  const isZoomAuthCodeLoading = useAppSelector(
    (state) => state.auth.isLoading.zoomAuthCode
  );

  const isDisconnectZoomLoading = useAppSelector(
    (state) => state.auth.isLoading.disconnectZoom
  );

  const isZoomOAuthUrlError = useAppSelector(
    (state) => state.auth.isError.zoomOAuthUrl
  );

  const isZoomAuthCodeError = useAppSelector(
    (state) => state.auth.isError.zoomAuthCode
  );

  const retryConnectZoom = useAppSelector(
    (state) => state.auth.retryConnectZoom
  );

  const history = useHistory();

  const { search } = useLocation();

  const {
    connectAnalyzeEmails,
    connectSendEmails,
    connectCalendar,
  } = useFirebase();

  const zoomAuthCode = useMemo(
    () => new URLSearchParams(search).get('zoomAuthCode'),
    [search]
  );

  const selectedIntegrations = useMemo(() => {
    const selectedIntegrationsQuery = new URLSearchParams(search).get(
      'selectedIntegrations'
    );

    return new Set(
      selectedIntegrationsQuery ? selectedIntegrationsQuery.split(',') : []
    );
  }, [search]);

  const isSendEmailsEnabled = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.REPLY_FROM_DASHBOARD
      )?.enabled,
    [user]
  );

  const isConnectMyZoomEnabled = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.CONNECT_MY_ZOOM
      )?.enabled,
    [user]
  );

  const isAnalyzeEmailsComingSoon =
    typeof userConnections?.gmailRead !== 'boolean';

  const isAnalyzeEmailsConnected = userConnections?.gmailRead === true;

  const isSendEmailsComingSoon =
    typeof userConnections?.gmailWrite !== 'boolean';

  const isSendEmailsConnected = userConnections?.gmailWrite === true;

  const isCalendarComingSoon = typeof userConnections?.calendar !== 'boolean';

  const isCalendarConnected = userConnections?.calendar === true;

  const isConnectZoomLoading =
    isZoomOAuthUrlLoading || isZoomAuthCodeLoading || isDisconnectZoomLoading;

  const isZoomComingSoon = typeof userConnections?.zoom !== 'boolean';

  const isZoomConnected = userConnections?.zoom === true;

  const zoomEmail = userConnections?.zoomEmail || '';

  const getGoogleIntegrationTooltip = useCallback(
    ({
      isComingSoon,
      isConnected,
      isDisabled,
    }: {
      isComingSoon: boolean;
      isConnected: boolean;
      isDisabled: boolean;
    }) => {
      if (isDisabled) {
        return '';
      }

      if (isComingSoon) {
        return 'Coming Soon: Enhanced Tools';
      }

      if (isConnected) {
        return 'You can disconnect your Google Account';
      }

      return '';
    },
    []
  );

  const getZoomIntegrationTooltip = useCallback(
    ({
      isComingSoon,
      isConnected,
      isDisabled,
    }: {
      isComingSoon: boolean;
      isConnected: boolean;
      isDisabled: boolean;
    }) => {
      if (isDisabled) {
        return '';
      }

      if (isComingSoon) {
        return 'Coming Soon: Enhanced Tools';
      }

      if (isConnected) {
        return 'You can disconnect your Zoom Account';
      }

      return '';
    },
    []
  );

  const getZoomIntegrationButtonVariant = useCallback(() => {
    if (retryConnectZoom) {
      return ButtonVariant.Danger;
    }

    if (
      isZoomOAuthUrlError ||
      isZoomAuthCodeError ||
      isZoomComingSoon ||
      isZoomConnected ||
      !isConnectMyZoomEnabled
    ) {
      return ButtonVariant.Secondary;
    }

    return ButtonVariant.Primary;
  }, [
    isConnectMyZoomEnabled,
    isZoomAuthCodeError,
    isZoomComingSoon,
    isZoomConnected,
    isZoomOAuthUrlError,
    retryConnectZoom,
  ]);

  const getZoomIntegrationButtonText = useCallback(() => {
    if (retryConnectZoom) {
      return 'Retry';
    }

    if (
      isZoomOAuthUrlError ||
      isZoomAuthCodeError ||
      (!isZoomComingSoon && !isConnectMyZoomEnabled)
    ) {
      return 'Contact Us';
    }

    return '';
  }, [
    isConnectMyZoomEnabled,
    isZoomAuthCodeError,
    isZoomComingSoon,
    isZoomOAuthUrlError,
    retryConnectZoom,
  ]);

  const getZoomIntegrationStatusText = useCallback(() => {
    if (!isZoomComingSoon && !isConnectMyZoomEnabled) {
      return 'This feature is disabled. Contact your Administrator.';
    }

    if (retryConnectZoom) {
      return 'Connection failed, try again, please.';
    }

    if (isZoomOAuthUrlError || isZoomAuthCodeError) {
      return 'Connection failed, please, contact us.';
    }

    return '';
  }, [
    isConnectMyZoomEnabled,
    isZoomAuthCodeError,
    isZoomComingSoon,
    isZoomOAuthUrlError,
    retryConnectZoom,
  ]);

  useEffect(() => {
    if (zoomOAuthUrl) {
      window.location.href = zoomOAuthUrl;
    }
  }, [zoomOAuthUrl]);

  useEffect(() => {
    if (zoomAuthCode) {
      dispatch(sendZoomAuthCode(zoomAuthCode));
      history.replace(`/orgs/${user?.orgId}/users/${user?.userId}`);
    }
  }, [dispatch, history, user, zoomAuthCode]);

  return useMemo(
    () => [
      {
        id: UserConnection.GmailRead,
        icon: GoogleIcon,
        title: 'Email Signal Analysis',
        description:
          'Analyze social signals in deal-related email communications',
        tooltip: getGoogleIntegrationTooltip({
          isComingSoon: isAnalyzeEmailsComingSoon,
          isConnected: isAnalyzeEmailsConnected,
          isDisabled: isConnectAnalyzeEmailsLoading || isUserConnectionsLoading,
        }),
        additionalInfo: isAnalyzeEmailsConnected ? user?.email : '',
        isComingSoon: isAnalyzeEmailsComingSoon,
        isLoading: isConnectAnalyzeEmailsLoading || isUserConnectionsLoading,
        isConnected: isAnalyzeEmailsConnected,
        buttonText:
          isAnalyzeEmailsComingSoon || isAnalyzeEmailsConnected
            ? ''
            : 'Continue with Google',
        buttonIcon:
          isAnalyzeEmailsComingSoon ||
          isConnectAnalyzeEmailsLoading ||
          isUserConnectionsLoading ||
          isAnalyzeEmailsConnected
            ? null
            : GoogleSmallIcon,
        buttonClassName:
          isAnalyzeEmailsComingSoon ||
          isConnectAnalyzeEmailsLoading ||
          isUserConnectionsLoading ||
          isAnalyzeEmailsConnected
            ? ''
            : 'account__google-button',
        isAnimated: selectedIntegrations.has(UserConnection.GmailRead),
        onConnect: connectAnalyzeEmails,
        onDisconnect: () => {
          dispatch(openRevokeGoogleScopesModal(UserConnection.GmailRead));
        },
      },
      ...(isSendEmailsEnabled
        ? [
            {
              id: UserConnection.GmailWrite,
              icon: GmailIcon,
              title: 'Send Emails',
              description:
                'Connect your Google email for enhanced productivity and collaboration',
              tooltip: getGoogleIntegrationTooltip({
                isComingSoon: isSendEmailsComingSoon,
                isConnected: isSendEmailsConnected,
                isDisabled:
                  isConnectSendEmailsLoading || isUserConnectionsLoading,
              }),
              additionalInfo: isSendEmailsConnected ? user?.email : '',
              isComingSoon: isSendEmailsComingSoon,
              isLoading: isConnectSendEmailsLoading || isUserConnectionsLoading,
              isConnected: isSendEmailsConnected,
              buttonText:
                isSendEmailsComingSoon || isSendEmailsConnected
                  ? ''
                  : 'Continue with Google',
              buttonIcon:
                isSendEmailsComingSoon ||
                isConnectSendEmailsLoading ||
                isUserConnectionsLoading ||
                isSendEmailsConnected
                  ? null
                  : GoogleSmallIcon,
              buttonClassName:
                isSendEmailsComingSoon ||
                isConnectSendEmailsLoading ||
                isUserConnectionsLoading ||
                isSendEmailsConnected
                  ? ''
                  : 'account__google-button',
              isAnimated: selectedIntegrations.has(UserConnection.GmailWrite),
              onConnect: connectSendEmails,
              onDisconnect: () => {
                dispatch(
                  openRevokeGoogleScopesModal(UserConnection.GmailWrite)
                );
              },
            },
          ]
        : []),
      {
        id: UserConnection.Calendar,
        icon: CalendarIcon,
        title: 'Calendar Sync',
        description:
          'Sync your Google Calendar for seamless sales meeting management',
        tooltip: getGoogleIntegrationTooltip({
          isComingSoon: isCalendarComingSoon,
          isConnected: isCalendarConnected,
          isDisabled: isConnectCalendarLoading || isUserConnectionsLoading,
        }),
        additionalInfo: isCalendarConnected ? user?.email : '',
        isComingSoon: isCalendarComingSoon,
        isLoading: isConnectCalendarLoading || isUserConnectionsLoading,
        isConnected: isCalendarConnected,
        buttonText:
          isCalendarComingSoon || isCalendarConnected
            ? ''
            : 'Continue with Google',
        buttonIcon:
          isCalendarComingSoon ||
          isConnectCalendarLoading ||
          isUserConnectionsLoading ||
          isCalendarConnected
            ? null
            : GoogleSmallIcon,
        buttonClassName:
          isCalendarComingSoon ||
          isConnectCalendarLoading ||
          isUserConnectionsLoading ||
          isCalendarConnected
            ? ''
            : 'account__google-button',
        isAnimated: selectedIntegrations.has(UserConnection.Calendar),
        onConnect: connectCalendar,
        onDisconnect: () => {
          dispatch(openRevokeGoogleScopesModal(UserConnection.Calendar));
        },
      },
      {
        id: UserConnection.Zoom,
        icon: ZoomIcon,
        title: 'Zoom Integration',
        description:
          'Connect your Zoom to remove friction and optimize prospect experience',
        tooltip: getZoomIntegrationTooltip({
          isComingSoon: isZoomComingSoon,
          isConnected: isZoomConnected && isConnectMyZoomEnabled,
          isDisabled: isConnectZoomLoading || isUserConnectionsLoading,
        }),
        additionalInfo: zoomEmail,
        statusText: getZoomIntegrationStatusText(),
        isComingSoon: isZoomComingSoon,
        isLoading: isConnectZoomLoading || isUserConnectionsLoading,
        isConnected: isZoomConnected && isConnectMyZoomEnabled,
        isError: isZoomOAuthUrlError || isZoomAuthCodeError,
        buttonText: getZoomIntegrationButtonText(),
        buttonVariant: getZoomIntegrationButtonVariant(),
        isAnimated: selectedIntegrations.has(UserConnection.Zoom),
        onConnect: () => {
          if (
            !isConnectMyZoomEnabled ||
            (!retryConnectZoom && (isZoomOAuthUrlError || isZoomAuthCodeError))
          ) {
            window.open(
              'mailto:support@substrata.me',
              '_blank',
              'noopener noreferrer'
            );
            return;
          }

          dispatch(getZoomOAuthUrl());
        },
        onDisconnect: () => {
          dispatch(disconnectZoom());
        },
      },
      {
        id: 'coming-soon',
        isPlaceholder: true,
      },
    ],
    [
      connectAnalyzeEmails,
      connectCalendar,
      connectSendEmails,
      dispatch,
      getGoogleIntegrationTooltip,
      getZoomIntegrationButtonText,
      getZoomIntegrationButtonVariant,
      getZoomIntegrationStatusText,
      getZoomIntegrationTooltip,
      isAnalyzeEmailsComingSoon,
      isAnalyzeEmailsConnected,
      isCalendarComingSoon,
      isCalendarConnected,
      isConnectAnalyzeEmailsLoading,
      isConnectCalendarLoading,
      isConnectSendEmailsLoading,
      isConnectZoomLoading,
      isSendEmailsComingSoon,
      isSendEmailsConnected,
      isSendEmailsEnabled,
      isConnectMyZoomEnabled,
      isUserConnectionsLoading,
      isZoomAuthCodeError,
      isZoomComingSoon,
      isZoomConnected,
      isZoomOAuthUrlError,
      retryConnectZoom,
      selectedIntegrations,
      user?.email,
      zoomEmail,
    ]
  );
};
