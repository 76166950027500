import React, { useEffect, useRef, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Skeleton } from 'antd';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { LogoPlaceholderIcon, Scrollbar } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getMeetingRecords } from '../../../../store/meetingRecords/meetingRecords.thunks';
import { getMeetingSourceIcon } from '../../../../utils/getMeetingSourceIcon';
import { Avatar } from '../../../../components/SoundWave/components/Avatar/Avatar';
import { EditIcon } from './icons/EditIcon';
import { openAssignAccountModal } from '../../../../store/relationshipFlows/relationshipFlows.slice';
import {
  Tooltip,
  TooltipPosition,
  TooltipTextAlign,
} from '../../../../components/SoundWave/components/Tooltip/Tooltip';
import { MeetingStatus } from '../../../../types/meetingDetails.types';
import { getOpenButtonTooltipProps } from './utils/getOpenButtonTooltipProps';
import { getOpenButtonText } from './utils/getOpenButtonText';
import { SortIcon } from './icons/SortIcon';
import {
  clearFilters,
  clearMeetingRecords,
  resetMeetingRecords,
  updateFiltersQueryParams,
  updateSorting,
} from '../../../../store/meetingRecords/meetingRecords.slice';
import { getCurrentSorting } from './utils/getCurrentSorting';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  InviteAssistantButton,
} from '../../../../components/SoundWave';
import { useMixpanel } from '../../../../hooks';
import { LockIcon } from './icons/LockIcon';
import { getRelationshipsFiltersQueryParams } from '../../../../store/meetingRecords/meetingRecords.utils';
import { appliedFiltersSelector } from '../../../../store/meetingRecords/meetingRecords.selectors';
import { notFoundMeetingsImg } from './icons';
import { checkFeatureAvailability } from './utils/checkForFeature';
import { Features } from '../../../../types';
import { convertSecondsToMinutes } from './utils/convertSecondsToMinutes';

export const MyMeetingsTable: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  const meetingRecords = useAppSelector((state) => state.meetingRecords.data);

  const { filtersQueryParams } = useAppSelector(
    (state) => state.meetingRecords
  );

  const isMeetingRecordsLoading = useAppSelector(
    (state) => state.meetingRecords.isLoading.meetingRecords
  );

  const nextPage = useAppSelector(
    (state) => state.meetingRecords.pagination.nextPage
  );

  const sortOrder = useAppSelector(
    (state) => state.meetingRecords.pagination.sortOrder
  );

  const sortBy = useAppSelector(
    (state) => state.meetingRecords.pagination.sortBy
  );

  const appliedFilters = useAppSelector(appliedFiltersSelector);

  const [isLastItemVisible, setIsLastItemVisible] = useState(false);

  const { trackEvent } = useMixpanel();

  const loadMoreRequest = useRef<Promise<unknown> & { abort: () => void }>();
  const scrollableNodeRef = useRef<HTMLElement | null>(null);

  const history = useHistory();

  const { show } = useIntercom();

  const rowVirtualizer = useVirtualizer({
    count: isMeetingRecordsLoading
      ? meetingRecords.length + 5
      : meetingRecords.length,
    getScrollElement: () => {
      return scrollableNodeRef.current;
    },
    estimateSize: () => 80,
    overscan: 0,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  const featureIsAvailable = checkFeatureAvailability(user, Features.SOUNDWAVE);

  const attrs = {
    container: {
      className: 'my-meetings-table',
    },
    headerRow: {
      className: `${
        !featureIsAvailable ? 'disabled ' : ''
      }my-meetings-table__row my-meetings-table__row--header`,
    },
    row: {
      className: 'my-meetings-table__row',
    },
    cell: {
      className: 'my-meetings-table__cell',
    },
    meetingNameCell: {
      className: 'my-meetings-table__cell',
      onClick: () => {
        const {
          sortBy: nextSortBy,
          sortOrder: nextSortOrder,
        } = getCurrentSorting('meetingName', sortBy, sortOrder);

        if (nextSortBy && nextSortOrder) {
          trackEvent('sort');
        } else {
          trackEvent('sortReset');
        }

        dispatch(
          updateSorting({ sortBy: nextSortBy, sortOrder: nextSortOrder })
        );
      },
    },
    meetingNameSortIcon: {
      className: `my-meetings-table__sort-icon${
        sortOrder && sortBy === 'meetingName'
          ? ` my-meetings-table__sort-icon--${sortOrder}`
          : ''
      }`,
    },
    startDateTimeCell: {
      className: 'my-meetings-table__cell',
      onClick: () => {
        const {
          sortBy: nextSortBy,
          sortOrder: nextSortOrder,
        } = getCurrentSorting('startDateTime', sortBy, sortOrder);

        if (nextSortBy && nextSortOrder) {
          trackEvent('sort');
        } else {
          trackEvent('sortReset');
        }

        dispatch(
          updateSorting({ sortBy: nextSortBy, sortOrder: nextSortOrder })
        );
      },
    },
    startDateTimeSortIcon: {
      className: `my-meetings-table__sort-icon${
        sortOrder && sortBy === 'startDateTime'
          ? ` my-meetings-table__sort-icon--${sortOrder}`
          : ''
      }`,
    },
    accountNameCell: {
      className: 'my-meetings-table__cell my-meetings-table__cell--disabled',
    },
    scrollable: {
      scrollableNodeProps: {
        ref: scrollableNodeRef,
      },
      className: 'my-meetings-table__scrollable',
    },

    emptyContent: {
      wrapper: {
        className: 'my-meetings-table__empty-content',
      },

      title: {
        className: 'my-meetings-table__empty-content-title',
      },

      text: {
        className: 'my-meetings-table__empty-content-text',
      },

      lockIcon: {
        className: 'my-meetings-table__empty-content-lock-icon',
      },

      clearFiltersButton: {
        className: `${
          appliedFilters?.length ? '' : 'hidden '
        }my-meetings-table__empty-content-clear-filters-button`,
        onClick: () => {
          dispatch(clearFilters());
          dispatch(
            updateFiltersQueryParams(getRelationshipsFiltersQueryParams([]))
          );
        },
      },

      image: {
        className: 'my-meetings-table__empty-content-not-found-img',
        src: notFoundMeetingsImg,
      },
    },

    rowsWrapper: {
      style: {
        height: `${rowVirtualizer.getTotalSize()}px`,
        width: '100%',
        position: 'relative' as const,
      },
    },
    sourceIcon: {
      className: 'my-meetings-table__source-icon',
    },
    editIcon: {
      className: 'my-meetings-table__edit-icon',
    },
    textSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 130,
      },
      title: {
        style: {
          width: 130,
          height: 24,
          margin: 0,
          borderRadius: 4,
        },
      },
    },
  };

  const renderRows = () =>
    virtualItems.map(({ index, size, start }) => {
      const isLoaderRow = index > meetingRecords.length - 1;
      const meetingRecord = meetingRecords[index];
      const MeetingSourceIcon = getMeetingSourceIcon(meetingRecord?.platform);
      const hasAccount = !!meetingRecord?.account;
      const dateString = meetingRecord
        ? DateTime.fromISO(meetingRecord.startDateTime).toFormat(
            'MMM d, yyyy, hh:mm a'
          )
        : '';

      const isReady =
        !!meetingRecord?.analysisDone &&
        meetingRecord?.meetingStatus !== MeetingStatus.IN_PROGRESS &&
        meetingRecord?.meetingStatus !==
          MeetingStatus.CALL_POST_PROCESSING_FAILED &&
        meetingRecord?.meetingStatus !== MeetingStatus.BOT_FAILED_TO_JOIN;

      const openButtonText = getOpenButtonText(
        meetingRecord?.meetingStatus,
        meetingRecord?.analysisDone
      );

      const handleOpenMeetingDetails = () => {
        if (!meetingRecord) {
          return;
        }

        if (meetingRecord.account) {
          history.push(
            `/orgs/${user?.orgId}/users/${user?.userId}/relationships/${meetingRecord.account.accountId}/meetings/${meetingRecord.touchPointId}`
          );
        } else {
          history.push(
            `/orgs/${user?.orgId}/users/${user?.userId}/meeting-records/${meetingRecord.touchPointId}`
          );
        }
      };

      const defineButtonVariant = () => {
        if (
          (!isReady &&
            meetingRecord?.meetingStatus ===
              MeetingStatus.CALL_POST_PROCESSING_FAILED) ||
          meetingRecord?.meetingStatus === MeetingStatus.BOT_FAILED_TO_JOIN
        ) {
          return 'error' as ButtonVariant;
        }
        if (
          !isReady &&
          meetingRecord?.meetingStatus === MeetingStatus.IN_PROGRESS
        ) {
          return 'success' as ButtonVariant;
        }

        return 'secondary' as ButtonVariant;
      };

      const props = {
        container: {
          key: index,
          className: 'my-meetings-table__row',
          style: {
            position: 'absolute' as const,
            top: 0,
            left: 0,
            width: '100%',
            height: `${size}px`,
            transform: `translateY(${start}px)`,
          },
        },
        title: {
          type: 'button' as const,
          disabled: !isReady,
          className: 'my-meetings-table__title',
          onClick: handleOpenMeetingDetails,
        },
        account: {
          type: 'button' as const,
          className: 'my-meetings-table__account',
          onClick: () => {
            if (!meetingRecord) {
              return;
            }

            if (meetingRecord.account) {
              history.push(
                `/orgs/${user?.orgId}/users/${user?.userId}/relationships/${meetingRecord.account.accountId}`
              );

              return;
            }

            dispatch(
              openAssignAccountModal({ eventId: meetingRecord.touchPointId })
            );
          },
        },
        accountLogo: {
          placeholder: LogoPlaceholderIcon,
          hasBorder: !meetingRecord?.account?.avatarSrc,
          shape: 'square' as const,
          src: meetingRecord?.account?.avatarSrc || '',
          className: meetingRecord?.account?.avatarSrc
            ? ''
            : 'my-meetings-table__account-logo',
        },
        tooltip: {
          simplified: true,
          textAlign: 'center' as TooltipTextAlign,
          position: TooltipPosition.BOTTOM_END,
          ...getOpenButtonTooltipProps(
            meetingRecord?.meetingStatus,
            meetingRecord?.analysisDone,
            show
          ),
        },
        button: {
          size: ButtonSize.XS,
          disabled: !isReady,
          variant: defineButtonVariant(),
          error:
            (!isReady &&
              meetingRecord?.meetingStatus ===
                MeetingStatus.CALL_POST_PROCESSING_FAILED) ||
            meetingRecord?.meetingStatus === MeetingStatus.BOT_FAILED_TO_JOIN,
          success:
            !isReady &&
            meetingRecord?.meetingStatus === MeetingStatus.IN_PROGRESS,
          onClick: handleOpenMeetingDetails,
        },
      };

      const meetingDurationValue =
        meetingRecord?.duration && typeof meetingRecord?.duration === 'number'
          ? convertSecondsToMinutes(meetingRecord.duration)
          : 'N/A';

      if (isLoaderRow) {
        return (
          <div {...props.container}>
            <div {...attrs.cell}>
              <Skeleton {...attrs.textSkeleton} />
            </div>
            <div {...attrs.cell}>
              <Skeleton {...attrs.textSkeleton} />
            </div>
            <div {...attrs.cell}>
              <Skeleton {...attrs.textSkeleton} />
            </div>
            <div {...attrs.cell}>
              <Skeleton {...attrs.textSkeleton} />
            </div>
            <div {...attrs.cell}>
              <Skeleton {...attrs.textSkeleton} />
            </div>
          </div>
        );
      }

      return (
        <div {...props.container}>
          <div {...attrs.cell}>
            <button {...props.title}>
              <MeetingSourceIcon {...attrs.sourceIcon} />
              <span>{meetingRecord?.meetingName || 'N/A'}</span>
            </button>
          </div>
          <div {...attrs.cell}>
            <span>{dateString}</span>
          </div>
          <div {...attrs.cell}>
            <span>{meetingDurationValue}</span>
          </div>
          <div {...attrs.cell}>
            <button {...props.account}>
              <Avatar {...props.accountLogo} />
              <span>{meetingRecord?.account?.name || 'N/A'}</span>
              {!hasAccount ? <EditIcon {...attrs.editIcon} /> : null}
            </button>
            {isReady ? (
              <Button {...props.button}>{openButtonText}</Button>
            ) : (
              <Tooltip {...props.tooltip}>
                <Button {...props.button}>{openButtonText}</Button>
              </Tooltip>
            )}
          </div>
        </div>
      );
    });

  useEffect(() => {
    const [lastItem] = [...virtualItems].reverse();
    setIsLastItemVisible(
      !!lastItem &&
        lastItem.index >= meetingRecords.length - 1 &&
        !!nextPage &&
        !isMeetingRecordsLoading
    );
  }, [meetingRecords, nextPage, isMeetingRecordsLoading, virtualItems]);

  useEffect(() => {
    const initialRequest = dispatch(getMeetingRecords());

    return () => {
      initialRequest.abort();
      loadMoreRequest.current?.abort();
      dispatch(clearMeetingRecords());
    };
  }, [dispatch, sortOrder, sortBy, filtersQueryParams]);

  useEffect(() => {
    if (isLastItemVisible) {
      loadMoreRequest.current = dispatch(getMeetingRecords());
    }
  }, [dispatch, isLastItemVisible]);

  useEffect(() => {
    return () => {
      dispatch(resetMeetingRecords());
    };
  }, [dispatch]);

  const noDataCheck = !meetingRecords.length && !isMeetingRecordsLoading;

  const noDataWithAppliedFilter =
    !meetingRecords.length &&
    !isMeetingRecordsLoading &&
    Object.values(filtersQueryParams).some((el) => el === true);

  const notActivatedState = (
    <div {...attrs.emptyContent.wrapper}>
      <LockIcon {...attrs.emptyContent.lockIcon} />
      <div {...attrs.emptyContent.title}>Meeting Recordings not available</div>
      <div {...attrs.emptyContent.text}>
        Ask your Admin to activate SoundWave functions
      </div>
    </div>
  );

  const noDataWithAppliedFilterState = (
    <div {...attrs.emptyContent.wrapper}>
      <div {...attrs.emptyContent.title}>No Meeting Records Found</div>
      <img {...attrs.emptyContent.image} alt="" />
      <div {...attrs.emptyContent.clearFiltersButton}>Clear all filters</div>
    </div>
  );

  const noDataState = (
    <div {...attrs.emptyContent.wrapper}>
      <div {...attrs.emptyContent.title}>No Meeting Records</div>
      <div {...attrs.emptyContent.text}>
        You don’t have any meetings recordings and analysis yet. Schedule your
        first meeting or Invite Assistant to the live Meeting now.
      </div>
      <InviteAssistantButton />
    </div>
  );

  const renderMeetingsTableBody = () => {
    if (!featureIsAvailable) {
      return notActivatedState;
    }
    if (noDataWithAppliedFilter) {
      return noDataWithAppliedFilterState;
    }
    if (noDataCheck) {
      return noDataState;
    }
    return <div {...attrs.rowsWrapper}>{renderRows()}</div>;
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.headerRow}>
        <div {...attrs.meetingNameCell}>
          <span>Event</span>
          <SortIcon {...attrs.meetingNameSortIcon} />
        </div>
        <div {...attrs.startDateTimeCell}>
          <span>Date and Time</span>
          <SortIcon {...attrs.startDateTimeSortIcon} />
        </div>
        <div {...attrs.startDateTimeCell}>
          <span>Duration</span>
          <SortIcon {...attrs.startDateTimeSortIcon} />
        </div>
        <div {...attrs.accountNameCell}>
          <span>Account</span>
        </div>
      </div>
      <Scrollbar {...attrs.scrollable}>{renderMeetingsTableBody()}</Scrollbar>
    </div>
  );
};
